import React from 'react';
import { Layout } from "../components/layout";

class NotFoundIndex extends React.Component {
    render() {
        return (
            <Layout breadcrumbs={["404"]}>
                <div id="not-found" className="container">
                    <h1>Page not Found</h1>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </Layout >
        )
    }
}

export default NotFoundIndex